import { createStore } from 'vuex'

/*
 * This store makes it possible to access the webConfig from the CMS in the store, everywhere in the app.
 */

const state = () => ({
  config: null,
  entryUrl: null,
})

const actions = {
  async nuxtServerInit({ commit }, context) {
    if (import.meta.server) {
      try {
        const { default: fetchWebConfig } = await import(
          '~/helpers/fetchWebConfig'
        )
        const webConfig = await fetchWebConfig(context)
        commit('SET_WEBCONFIG', webConfig)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('🔺 Error fetching webConfig:', error)
      }

      const route = useRoute()
      const entryUrl = route.fullPath // Example of a possible value: /fr/register
      commit('SET_ENTRY_URL', entryUrl)
    }
  },
}

const mutations = {
  SET_WEBCONFIG(state, webConfig) {
    state.config = webConfig
  },

  SET_ENTRY_URL(state, entryUrl) {
    state.entryUrl = entryUrl
  },
}

export default {
  state,
  mutations,
  actions,
}

export const useWebConfig = createStore({
  state,
  actions,
  mutations,
})
