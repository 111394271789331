
// @ts-nocheck


export const localeCodes =  [
  "se",
  "se-en",
  "fr",
  "fr-en",
  "be-fr",
  "be-en",
  "nl",
  "nl-en",
  "no",
  "no-en",
  "es",
  "es-en",
  "dk",
  "dk-en",
  "fi",
  "fi-en",
  "ch",
  "ch-en",
  "at",
  "at-en",
  "de",
  "de-en"
]

export const localeLoaders = {
  "se": [{ key: "../i18n/json/sv.json", load: () => import("../i18n/json/sv.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_sv_json" */), cache: true }],
  "se-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }],
  "fr": [{ key: "../i18n/json/fr_FR.json", load: () => import("../i18n/json/fr_FR.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_fr_FR_json" */), cache: true }],
  "fr-en": [{ key: "../i18n/json/en_FR.json", load: () => import("../i18n/json/en_FR.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_FR_json" */), cache: true }],
  "be-fr": [{ key: "../i18n/json/fr_FR.json", load: () => import("../i18n/json/fr_FR.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_fr_FR_json" */), cache: true }],
  "be-en": [{ key: "../i18n/json/en_BE.json", load: () => import("../i18n/json/en_BE.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_BE_json" */), cache: true }],
  "nl": [{ key: "../i18n/json/nl.json", load: () => import("../i18n/json/nl.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_nl_json" */), cache: true }],
  "nl-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }],
  "no": [{ key: "../i18n/json/no.json", load: () => import("../i18n/json/no.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_no_json" */), cache: true }],
  "no-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }],
  "es": [{ key: "../i18n/json/es_ES.json", load: () => import("../i18n/json/es_ES.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_es_ES_json" */), cache: true }],
  "es-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }],
  "dk": [{ key: "../i18n/json/da.json", load: () => import("../i18n/json/da.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_da_json" */), cache: true }],
  "dk-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }],
  "fi": [{ key: "../i18n/json/fi.json", load: () => import("../i18n/json/fi.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_fi_json" */), cache: true }],
  "fi-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }],
  "ch": [{ key: "../i18n/json/de_CH.json", load: () => import("../i18n/json/de_CH.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_de_CH_json" */), cache: true }],
  "ch-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }],
  "at": [{ key: "../i18n/json/de_AT.json", load: () => import("../i18n/json/de_AT.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_de_AT_json" */), cache: true }],
  "at-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }],
  "de": [{ key: "../i18n/json/de_DE.json", load: () => import("../i18n/json/de_DE.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_de_DE_json" */), cache: true }],
  "de-en": [{ key: "../i18n/json/en.json", load: () => import("../i18n/json/en.json" /* webpackChunkName: "locale__builds_nextory_web_nx_web_i18n_json_en_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../vue-i18n.options.ts?hash=0a677049&config=1" /* webpackChunkName: "__vue_i18n_options_ts_0a677049" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./vue-i18n.options.ts",
  "locales": [
    {
      "code": "se",
      "language": "sv-SE",
      "name": "Swedish",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/sv.json"
      ]
    },
    {
      "code": "se-en",
      "language": "en-SE",
      "name": "English (Sweden)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    },
    {
      "code": "fr",
      "language": "fr-FR",
      "name": "Français",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/fr_FR.json"
      ]
    },
    {
      "code": "fr-en",
      "baseFile": "en.json",
      "language": "en-FR",
      "name": "English",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en_FR.json"
      ]
    },
    {
      "code": "be-fr",
      "language": "fr-BE",
      "name": "Français (Belge)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/fr_FR.json"
      ]
    },
    {
      "code": "be-en",
      "baseFile": "en.json",
      "language": "en-BE",
      "name": "English (Belgium)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en_BE.json"
      ]
    },
    {
      "code": "nl",
      "language": "nl-NL",
      "name": "Dutch",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/nl.json"
      ]
    },
    {
      "code": "nl-en",
      "language": "en-NL",
      "name": "English (Netherlands)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    },
    {
      "code": "no",
      "language": "nb-NO",
      "name": "Norwegian",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/no.json"
      ]
    },
    {
      "code": "no-en",
      "language": "en-NO",
      "name": "English (Norway)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    },
    {
      "code": "es",
      "language": "es-ES",
      "name": "Spanish",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/es_ES.json"
      ]
    },
    {
      "code": "es-en",
      "language": "en-ES",
      "name": "English (Spain)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    },
    {
      "code": "dk",
      "language": "da-DK",
      "name": "Danish",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/da.json"
      ]
    },
    {
      "code": "dk-en",
      "language": "en-DK",
      "name": "English (Denmark)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    },
    {
      "code": "fi",
      "language": "fi-FI",
      "name": "Finnish",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/fi.json"
      ]
    },
    {
      "code": "fi-en",
      "language": "en-FI",
      "name": "English (Finland)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    },
    {
      "code": "ch",
      "language": "de-CH",
      "name": "Switzerland",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/de_CH.json"
      ]
    },
    {
      "code": "ch-en",
      "language": "en-CH",
      "name": "English (Switzerland)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    },
    {
      "code": "at",
      "language": "de-AT",
      "name": "Austria",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/de_AT.json"
      ]
    },
    {
      "code": "at-en",
      "language": "en-AT",
      "name": "English (Austria)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    },
    {
      "code": "de",
      "language": "de-DE",
      "name": "German",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/de_DE.json"
      ]
    },
    {
      "code": "de-en",
      "language": "en-DE",
      "name": "English (Germany)",
      "files": [
        "/builds/nextory/web/nx-web/i18n/json/en.json"
      ]
    }
  ],
  "defaultLocale": "se-en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "i18n/json/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "se-en",
    "redirectOn": "no prefix",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "se",
    "language": "sv-SE",
    "name": "Swedish",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/sv.json"
      }
    ]
  },
  {
    "code": "se-en",
    "language": "en-SE",
    "name": "English (Sweden)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr-FR",
    "name": "Français",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/fr_FR.json"
      }
    ]
  },
  {
    "code": "fr-en",
    "baseFile": "en.json",
    "language": "en-FR",
    "name": "English",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en_FR.json"
      }
    ]
  },
  {
    "code": "be-fr",
    "language": "fr-BE",
    "name": "Français (Belge)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/fr_FR.json"
      }
    ]
  },
  {
    "code": "be-en",
    "baseFile": "en.json",
    "language": "en-BE",
    "name": "English (Belgium)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en_BE.json"
      }
    ]
  },
  {
    "code": "nl",
    "language": "nl-NL",
    "name": "Dutch",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/nl.json"
      }
    ]
  },
  {
    "code": "nl-en",
    "language": "en-NL",
    "name": "English (Netherlands)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  },
  {
    "code": "no",
    "language": "nb-NO",
    "name": "Norwegian",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/no.json"
      }
    ]
  },
  {
    "code": "no-en",
    "language": "en-NO",
    "name": "English (Norway)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  },
  {
    "code": "es",
    "language": "es-ES",
    "name": "Spanish",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/es_ES.json"
      }
    ]
  },
  {
    "code": "es-en",
    "language": "en-ES",
    "name": "English (Spain)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  },
  {
    "code": "dk",
    "language": "da-DK",
    "name": "Danish",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/da.json"
      }
    ]
  },
  {
    "code": "dk-en",
    "language": "en-DK",
    "name": "English (Denmark)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  },
  {
    "code": "fi",
    "language": "fi-FI",
    "name": "Finnish",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/fi.json"
      }
    ]
  },
  {
    "code": "fi-en",
    "language": "en-FI",
    "name": "English (Finland)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  },
  {
    "code": "ch",
    "language": "de-CH",
    "name": "Switzerland",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/de_CH.json"
      }
    ]
  },
  {
    "code": "ch-en",
    "language": "en-CH",
    "name": "English (Switzerland)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  },
  {
    "code": "at",
    "language": "de-AT",
    "name": "Austria",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/de_AT.json"
      }
    ]
  },
  {
    "code": "at-en",
    "language": "en-AT",
    "name": "English (Austria)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  },
  {
    "code": "de",
    "language": "de-DE",
    "name": "German",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/de_DE.json"
      }
    ]
  },
  {
    "code": "de-en",
    "language": "en-DE",
    "name": "English (Germany)",
    "files": [
      {
        "path": "/builds/nextory/web/nx-web/i18n/json/en.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
