/**
 * Takes a string and capitalizes the first letter of each word,
 * while making the rest lowercase.
 */
export default function capitalizeWords(str: string = ''): string {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}
