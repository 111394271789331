import consola from 'consola'

import { parseLocaleIso } from '~/helpers/localizationFormatter'

const logger = consola.withTag('Utils - fetchEditorialEntries')

export default async function fetchEditorialEntries(
  bookCount: number = 6,
  format: string = 'ebook,audiobook'
): Promise<
  Array<{
    id: number
    products: any[]
    title: string
  }>
> {
  const { $hummingbird, $i18n } = useNuxtApp()

  const { language } = parseLocaleIso($i18n.localeProperties.value.language)

  const title = $i18n.t('catalogue.editorial_entries_title')

  try {
    const editorialEntriesItems = await $hummingbird.catalog
      .getSearchEditorialEntries(0, 12, language)
      .then(res => res.data.editorial_entries || [])
      .catch(err => {
        logger.error('Failed to fetch editorial entries:', err)

        return []
      })

    const filteredEntries = editorialEntriesItems.filter(entry =>
      ['selection_entry', 'promotional_entry'].includes(entry.type)
    )

    const normalizedEntries = filteredEntries.map(entry => {
      if (entry.type === 'selection_entry') {
        return entry.selection_entry.selection
      } else {
        return entry.promotional_entry.selection
      }
    })

    const editorialEntries = await Promise.all(
      normalizedEntries.map(async selection => {
        const products = await $hummingbird.catalog
          .getSelectionProducts(selection.id, 0, bookCount, format, language)
          .then(res => res.data.products || [])
          .catch(err => {
            logger.error(
              `Failed to fetch products for selection ID ${selection.id}:`,
              err
            )

            return []
          })

        return {
          id: selection.id,
          products: products.slice(0, bookCount),
          title,
        }
      })
    )

    return editorialEntries
  } catch (err) {
    logger.error('An error occurred while fetching editorial entries:', err)

    return []
  }
}
