import type { AxiosPromise } from 'axios'

import BaseApi from './baseApi'
import convert from './helpers/convert'

export default class Users extends BaseApi {
  /**
   * Ask for the Reset password email to a user
   */
  resetPassword(data: {
    email: string // user email to send reset password email to
  }): AxiosPromise<{
    message: string // Confirmation message
  }> {
    return convert(
      this.http({
        method: 'post',
        url: '/user/v1/forgot_password',
        data,
      })
    )
  }

  /**
   * Verify the hash to validate the password change
   */
  isValidResetPasswordRequest(params: {
    hash: string
    user_id: Int32Array
  }): AxiosPromise<void> {
    return convert(
      this.http({
        method: 'get',
        url: '/user/v1/reset_password',
        params,
      })
    )
  }

  /**
   * Update the user password (end of the reset password)
   */
  updateResetPassword(data: {
    hash: string // the modern hash from the received email url
    password: string // the new password to update
    password_confirmation: string // the confirmation of the new password
    token: string // the legacy token
    user_id: number // the modern user_id from the received email url
  }): AxiosPromise<{
    message: string // Confirmation message
  }> {
    return this.http({
      method: 'patch',
      url: '/user/v1/reset_password',
      data: {
        hash: data.hash,
        new_password: data.password,
        user_id: data.user_id,
      },
    })
  }
}
