import { init, captureException } from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    // Provide a dummy function to avoid errors in case of missing Sentry configuration
    nuxtApp.provide('sentry', {
      captureException: (error: Error) => {
        // eslint-disable-next-line no-console
        console.error('Sentry captureException\n', error)
      },
    })

    return
  }

  // Initialize Sentry synchronously with minimal configuration
  init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,

    tracesSampleRate: sentry.traceSampleRate,

    // Disabling Distributed Tracing, since the Nextory API doesn't support it yet
    // Docs: https://docs.sentry.io/platforms/javascript/tracing/trace-propagation/#disabling-distributed-tracing
    tracePropagationTargets: [],

    replaysSessionSampleRate: sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: 1.0,
  })

  nuxtApp.provide('sentry', {
    captureException,
  })

  // Lazy-load both integrations 3 seconds after the page has loaded
  document.onreadystatechange = async () => {
    if (document.readyState === 'complete') {
      try {
        await new Promise<void>(resolve => setTimeout(resolve, 3000))
        const { addIntegration, browserTracingIntegration, replayIntegration } =
          await import('@sentry/vue')

        // Add browserTracingIntegration
        addIntegration(
          browserTracingIntegration({
            router,
            routeLabel: 'path',
          })
        )

        // Add replayIntegration
        addIntegration(replayIntegration({}))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to load Sentry integrations:', error)
      }
    }
  }
})
