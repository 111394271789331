// Redirects URLs having an extra slash to the same URL without the slash.
// Must be used with the router: trailingSlash option set to false in Nuxt config.
// We use a 301 permanent redirect.
//
// From: /hey/there/mr/president/
// To:   /hey/there/mr/president
export default defineNuxtRouteMiddleware(to => {
  if (to.path !== '/' && to.path.endsWith('/')) {
    const { hash, path, query } = to
    const nextPath = path.replace(/\/+$/, '') || '/'
    const nextRoute = { path: nextPath, query, hash }

    return navigateTo(nextRoute, { redirectCode: 301 })
  }
})
