<template>
  <div class="d-flex" aria-hidden="true">
    <span
      v-for="index in 5"
      :key="index"
      class="icon-16 icon--star"
      :class="[
        index <= Math.round(rating) ? 'text--yellow-600' : 'text--sand-300',
      ]"
    />
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    rating: {
      type: Number,
      required: true,
    },
  },
})
</script>
