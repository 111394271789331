import type { Route } from 'vue-router/types/router'

/**
 * Get the id from the slug param
 *
 * Examples:
 * crime-in-the-usa-22 -> 22
 * i-like-to-eat-pizza-1 -> 1
 */
export function getIdFromSlugParam(slug: string): number {
  return Number(slug.split('-').pop())
}

/**
 * Create a slug from a string and an id
 *
 * Examples:
 * Crime in the USA, 22 -> crime-in-the-usa-22
 * I like to eat pizza 🍕, 1 -> i-like-to-eat-pizza-1
 */
export function createSlug(string: string = '', id: number = 0): string {
  return `${slugify(string)}-${id}`
}

/**
 * Create a slug from a string
 *
 * Examples:
 * Crime in the USA -> crime-in-the-usa
 * I like to eat pizza 🍕 -> i-like-to-eat-pizza
 */
export function slugify(text: string = ''): string {
  return text
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

/**
 * Prepare a redirect, if the slug is not correct
 *
 * This is used to make sure the URL is correct, e.g:
 * /i-love-pancakes-123 ❌ -> /i-prefer-crepes-123 ✔
 *
 * If null is returned, user doesn't need to be redirected since the slug is correct
 */
export function handleSlugRedirect(
  currentSlug: string,
  correctSlug: string,
  destinationPage: string,
  localePath: (object: any) => string,
  query: Route['query'] = {}
): string | null {
  if (currentSlug !== correctSlug) {
    return localePath({
      name: destinationPage,
      params: {
        slug: correctSlug,
      },
      query,
    })
  }

  return null
}
