import type { Request } from '../request'

export default class BaseApi {
  protected http: typeof Request.prototype.http
  protected request: Request

  constructor(request: Request) {
    // Forward request.http to this.http, but make sure to bind it to the request instance
    this.http = request.http.bind(request)
    this.request = request
  }
}
