/**
 * Some markets, like Belgium, don't have a default language.
 * So we need to redirect them to a specific language as a fallback.
 */
export default defineNuxtRouteMiddleware(to => {
  // Mapping of markets to their fallback locales
  const mappings = {
    be: 'be-fr',
  } as Record<string, string>

  // Split the path into segments and remove any empty strings
  const pathSegments = to.path.split('/').filter(Boolean)
  const currentMarket = pathSegments[0]

  // Check if the current market needs to be redirected
  if (mappings[currentMarket]) {
    // Replace the market code with the fallback locale
    const newPath = to.fullPath.replace(
      `/${currentMarket}`,
      `/${mappings[currentMarket]}`
    )

    return navigateTo(newPath, { redirectCode: 302 })
  }
})
