import type { AxiosPromise } from 'axios'

import type { OnboardingGateway } from '../api-types/OnboardingGateway'

import BaseApi from './baseApi'

export class DeviceOpportunities extends BaseApi {
  getDeviceOpportunities(promotionalCodes?: {
    campaignCode?: string | undefined
    channel?: string | undefined
    giftcardCode?: string | undefined
  }): AxiosPromise<OnboardingGateway> {
    return this.http({
      method: 'get',
      url: '/obgateway/v1/subscriptions',
      params: {
        campaign_code: promotionalCodes?.campaignCode,
        giftcard_code: promotionalCodes?.giftcardCode,
        channel: promotionalCodes?.channel,
      },
    }).then(response => {
      // FIXME: In the response, we need to remove the feature line that has the text "Label.KJELL.SubscriptionFeaturePoints" and leave the rest as is.
      // This temporary code needs to be removed once the backend is fixed.
      // https://nextory.atlassian.net/browse/ACQ-1953

      const data: OnboardingGateway = response.data

      data.feature_keys = data.feature_keys.filter(
        feature => feature.text !== 'Label.KJELL.SubscriptionFeaturePoints'
      )

      return {
        ...response,
        data,
      }
    })
  }
}
