import type { AxiosPromise } from 'axios'

import {
  errorToLegacyError,
  isModernError,
} from '../converters/errorToLegacyError'

/**
 * Helpers for converting modern responses to legacy responses.
 * Also converts modern errors to legacy errors.
 *
 * Converter is optional, in case you only want to convert errors.
 */
export default function <ModernType, LegacyType>(
  promise: AxiosPromise,
  converter:
    | ((source: ModernType, paramsModifier: any | undefined) => LegacyType)
    | undefined = undefined,
  paramsModifier: any | undefined = undefined
): AxiosPromise<LegacyType> {
  return new Promise((resolve, reject) => {
    promise
      .then(response => {
        if (converter !== undefined) {
          response.data = converter(response.data, paramsModifier)
        }
        resolve(response)
      })
      .catch(e => {
        if (isModernError(e?.response?.data)) {
          // Converting modern error to legacy error
          e.response.data = errorToLegacyError(e.response.data)
        }

        reject(e)
      })
  })
}
