import initStore from '../store'

// For an easier migration to Nuxt 3, we still use Vuex (but in version 4)
// TODO: Move to Pinia 🍍
export default defineNuxtPlugin({
  name: 'vuex',
  async setup(nuxtApp) {
    // Reset state to avoid reactivity issues
    const store = initStore()
    const vuexState = useState('vuex')

    nuxtApp.vueApp.use(store)
    nuxtApp.provide('store', store)

    // Server side: prepare store
    if (import.meta.server) {
      // Let's call nuxtServerInit on every module
      await store.dispatch('nuxtServerInit', nuxtApp)
      vuexState.value = store.state
    }

    // Client side: restore store
    if (import.meta.client) {
      store.replaceState(vuexState.value)
    }
  },
})
