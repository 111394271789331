export function usePageFullHeight() {
  const verticalHeight = ref('1vh')

  /* 1️⃣ add the cssVars computed in your template or page :style="verticalHeightCssVar"
   * 2️⃣ use the class .is-fullheight in your template */
  const verticalHeightCssVar = computed(() => `--vh: ${verticalHeight.value}`)

  function getVerticalHeight() {
    // resize viewport to have a correct rendering on mobile browsers
    if (typeof window !== 'undefined') {
      if (typeof window !== 'undefined') {
        const screenHeight = Math.max(
          Math.min(window.innerHeight, window.outerHeight),
          document.documentElement.clientHeight
        )

        verticalHeight.value = `${screenHeight * 0.01}px`
      }
    }
  }

  function getVerticalHeightEventListener() {
    window.addEventListener('resize', getVerticalHeight)
  }

  onMounted(() => {
    getVerticalHeight()
    getVerticalHeightEventListener()
  })

  onBeforeMount(() => window.removeEventListener('resize', getVerticalHeight))

  return {
    verticalHeightCssVar,
  }
}
