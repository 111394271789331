import { type AxiosResponse } from 'axios'
import type { LogLevel } from 'consola'
import consola from 'consola'
import { getCurrentInstance } from 'vue'
import type { RequestInterface } from './interfaces/request'
// @ts-ignore
import { version } from '~/package'

const logger = consola.withTag('Hummingbird (Nextory)')

class AxiosLikeError extends Error {
  constructor(
    message: string,
    public code: string,
    public config: any,
    public request: any,
    public response: any
  ) {
    super(message)
  }
}

export class Request implements RequestInterface {
  public readonly headers: Record<string, string>
  private logLevel: LogLevel

  constructor(
    private origin: string, // eg. https://staging.nextory.app
    private baseURL: string,
    private countryCode: string | undefined = undefined, // Format: "SE"
    private locale: string | undefined = undefined, // Format: "sv"
    sandboxHeaders: Record<string, string> = {}
  ) {
    this.logLevel = /* info (number) */ 3
    this.headers = this.prepareHeaders(sandboxHeaders)
  }

  /**
   * This function does the bridging between our old Axios requests and the new useFetch from Nuxt 3.
   */
  public http({
    baseURL,
    data,
    headers,
    method,
    params,
    query,
    url,
  }): Promise<AxiosResponse> {
    const options = {
      method,
      query,
      body: data,
      headers: {
        ...this.headers,
        ...headers,
      },
      baseURL: baseURL || this.baseURL,
    }
    const urlWithParams = new URL(url, options.baseURL)

    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key] !== undefined) {
          urlWithParams.searchParams.append(key, params[key])
        }
      })
    }

    // Only get /route?param=value part of the URL now (no origin, but both path and search)
    url = urlWithParams.pathname + urlWithParams.search

    return new Promise((resolve, reject) => {
      // There, we can't always use useFetch, since it's not always available (e.g. in a serverside-rendered store which needs to fetch data)
      // So we need to check if it's available, and if not, use $fetch instead.
      const instance = getCurrentInstance()
      if (tryUseNuxtApp() && (!instance || instance?.isMounted)) {
        useFetch(url, {
          ...options,
          deep: false, // Disable reactivity
        }).then(({ data, error }) => {
          if (error.value) {
            // Simulate an Axios error
            const statusCode = error.value?.statusCode || 500
            const errorBody = error.value?.data || 'Unknown error'
            const errorName = `Error fetching data from: ${url} - ${statusCode} ${JSON.stringify(errorBody)}`

            reject(
              new AxiosLikeError(errorName, 'ERR_BAD_RESPONSE', null, null, {
                data: errorBody,
                status: statusCode,
                statusText: errorName,
                headers: options.headers,
                config: {},
              })
            )

            return
          }

          // Transform it to AxiosResponse, to match the legacy code
          resolve({
            data: data.value,
            status: 200,
            statusText: 'OK',
            headers: options.headers,
            config: {},
          })
        })
      } else {
        $fetch(url, options)
          .then(response => {
            resolve({
              data: response,
              status: 200,
              statusText: 'OK',
              headers: options.headers,
              config: {},
            })
          })
          .catch(error => {
            // Simulate an Axios error
            const statusCode = error.response?.status || 500
            const errorBody = error.response?.data || 'Unknown error'
            const errorName = `Error fetching data from: ${url} - ${statusCode} ${JSON.stringify(errorBody)}`

            reject(
              new AxiosLikeError(errorName, 'ERR_BAD_RESPONSE', null, null, {
                data: errorBody,
                status: statusCode,
                statusText: errorName,
                headers: options.headers,
                config: {},
              })
            )
          })
      }
    })
  }

  private prepareHeaders(sandboxHeaders: Record<string, string>) {
    const headers = {
      'Content-Type': 'application/json',
      'X-Application-Id': '203', // Web
      'X-Model': 'web',
      'X-App-Version': version,
      'X-Country-Code': this.countryCode,
      'X-Locale': `${this.locale}_${this.countryCode}`, // Format locale_COUNTRY, e.g. sv_SE
    } as Record<string, string>

    if (import.meta.server) {
      // Origin must be specified in SSR (but not in a browser - it'll add it itself)
      headers.Origin = this.origin
    }

    // Add sandbox headers
    Object.keys(sandboxHeaders).forEach(key => {
      headers[key] = sandboxHeaders[key]
    })

    return headers
  }

  private setHeader(header: string, value: string | null | undefined) {
    if (value) {
      this.headers[header] = value
    } else {
      delete this.headers[header]
    }
  }

  _setDeviceId(deviceId: string) {
    this.setHeader('X-Device-Id', deviceId)
  }

  _setCountry(country: string | undefined) {
    this.setHeader('X-Country-Code', country)
  }

  _setLogLevel(logLevel: LogLevel) {
    this.logLevel = logLevel
    logger.level = logLevel
  }

  _setAuthToken(authToken?: string) {
    this.setHeader('X-Login-Token', authToken)
  }
}
