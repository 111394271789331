<template>
  <div
    class="nx-loader m-auto"
    :class="{
      'theme-dark': theme === 'dark',
      'theme-light': theme === 'light',
    }"
  >
    <div class="nx-loader__circle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <g transform="translate(-7343 -3906)">
          <g transform="translate(8232.536 3156.884)">
            <path
              d="M-857.365,763.316a39.993,39.993,0,0,0-22.171,35.806,40,40,0,0,0,40,40,40,40,0,0,0,40-40,39.993,39.993,0,0,0-22.171-35.806"
              transform="translate(0 0)"
              fill="none"
              stroke="#fff"
              stroke-width="2"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator: v => ['dark', 'light'].includes(v),
    },
  },
})
</script>

<style lang="scss">
.nx-loader {
  position: relative;
  width: 100px;

  .nx-loader__circle {
    svg {
      animation: rotate 1s infinite linear;
    }
  }

  &.theme {
    // theme-dark
    &-dark {
      .nx-loader__circle svg {
        path {
          stroke: $gray-400;
        }
      }
    }
    // theme-light
    &-light {
      .nx-loader__circle svg {
        path {
          stroke: $pink-600;
        }
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
