/**
 * In development, redirect from 127.0.0.1 to localhost (and keep the port and path)
 *
 * This redirect shouldn't be needed since Nuxt starts on localhost by default,
 * but it's for developers quality of life.
 */
export default defineNuxtRouteMiddleware(to => {
  const headers = useRequestHeaders(['host'])
  if (process.env.NODE_ENV !== 'development' || !headers.host) {
    return
  }

  const host = headers.host
  if (host.includes('127.0.0.1')) {
    const port = host.split(':')[1]
    const redirectUrl = `http://localhost:${port}${to.fullPath}`

    // eslint-disable-next-line no-console
    console.log('[DEV] Redirecting from 127.0.0.1 to localhost', redirectUrl)

    return navigateTo(redirectUrl, { external: true })
  }
})
