// Define the state
import { createStore } from 'vuex'

const state = () => ({
  asFullscreenComponent: false,
})

const getters = {
  asFullscreenComponent(state) {
    return state.asFullscreenComponent
  },
}

// Define the mutations
const mutations = {
  setAsFullscreenComponent(state, value) {
    state.asFullscreenComponent = value
  },
}

// Define the actions
const actions = {
  setAsFullscreenComponent({ commit }, value) {
    commit('setAsFullscreenComponent', value)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

export const useLayout = createStore({
  state,
  getters,
  mutations,
  actions,
})
