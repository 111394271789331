import { createStore } from 'vuex'

const state = () => ({
  success: null,
  danger: null,
  info: null,
  warning: null,
})

const getters = {
  getAllFlashes(state) {
    return state
  },
}

const mutations = {
  SET_FLASH(state, { message, variant }) {
    state[variant] = message
  },

  RESET_FLASH(state, variant) {
    state[variant] = null
  },
}

const actions = {
  flashSuccess({ commit }, message) {
    commit('SET_FLASH', { message, variant: 'success' })
  },

  flashError({ commit }, message) {
    commit('SET_FLASH', { message, variant: 'error' })
  },

  flashReset({ commit }, variant) {
    commit('RESET_FLASH', variant)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

export const useFlashMessage = createStore({
  state,
  getters,
  mutations,
  actions,
})
