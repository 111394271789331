/**
 * Orange Tunisia is a Youboox legacy login option, now hosted separately on a subdomain.
 *
 * Path to redirect: /{locale}/profile/providers/orange (keep any query params)
 * Production: https://tn.nextory.com/
 * Staging: https://tn.staging.nextory.app/
 */
export default defineNuxtRouteMiddleware(to => {
  const { $i18n } = useNuxtApp()
  const {
    public: { ENVIRONMENT: environment },
  } = useRuntimeConfig()

  const orangeTunisiaDomain =
    environment === 'production'
      ? 'https://tn.nextory.com'
      : 'https://tn.staging.nextory.app'
  const orangeTunisiaPath = `/${$i18n.locale.value}/profile/providers/orange`
  const path = to.fullPath

  if (path.includes(orangeTunisiaPath)) {
    const url = new URL(`${orangeTunisiaDomain}${orangeTunisiaPath}`)

    const query = to.query
    Object.keys(query).forEach(key => url.searchParams.append(key, query[key]))

    return navigateTo(url.toString(), { external: true })
  }
})
