<template>
  <div>
    <button
      ref="searchButton"
      class="align-items-center button-header"
      aria-haspopup="true"
      :aria-expanded="isSearchExpanded ? 'true' : 'false'"
      aria-controls="search-popup"
      @click="showSearchDialog"
    >
      <span
        id="search-label"
        class="icon-24"
        :class="[darkTheme ? 'text--sand-200' : 'text--sand-700', icon]"
      >
        {{ label }}
      </span>
    </button>

    <section
      v-show="isSearchExpanded"
      ref="searchDialog"
      role="dialog"
      aria-modal="true"
      aria-labelledby="search-label"
      :style="verticalHeightCssVar"
      class="fullscreen-navigation is-fullheight bg--purple-700"
    >
      <div class="container ps-24 py-16 py-sm-32 py-md-40">
        <div class="row">
          <div class="d-flex justify-content-end align-items-center p-12">
            <button
              ref="fullscreen-close-button"
              data-test="close-search-icon"
              class="button button--small button--primary"
              @click="hideSearchDialog"
            >
              {{ $t('global.button_close') }}
              <span class="ms-8 icon-16 icon--close-m" />
            </button>
          </div>
        </div>
      </div>
      <div id="search-popup" class="container text--white">
        <form class="header__search-form" @submit.prevent="handleSearch">
          <h2 class="text--body">
            <label class="header__label text--purple-300" for="search">
              {{ $t('catalogue.search.input_label') }}
            </label>
          </h2>

          <div class="header__input-wrapper hstack">
            <input
              id="search"
              ref="search"
              v-model="search"
              data-test="input-search"
              class="header__input-field headline--big"
              :class="search ? 'text--sand-200' : 'text--purple-500'"
              type="text"
              :placeholder="$t('search.ghostcopy')"
              @update:model-value="updateSuggestions"
            />

            <div class="mt-0 mt-xxl-16 hstack">
              <button
                type="button"
                :aria-label="$t('catalogue.search.reset')"
                class="header__input-reset d-inline-block"
                :class="{
                  'd-none': !search,
                }"
                @click="resetSearch"
              >
                <span class="icon-24 icon--close" />
              </button>
              <NuxtLink
                :to="localePath({ name: 'search', query: { q: search } })"
                class="button button--purple d-none d-xxl-inline-block ms-8"
                :class="{
                  'disabled pe-none': !search,
                }"
                @click="hideSearchDialog"
              >
                {{ $t('catalogue.search.input_label') }}
              </NuxtLink>
            </div>
          </div>

          <div v-if="suggestions.length > 0" class="mt-16">
            <NuxtLink
              v-for="suggestion in suggestions"
              :key="suggestion"
              :to="localePath({ name: 'search', query: { q: suggestion } })"
              class="d-block text--white search__suggestion text-decoration-none py-8"
              @click="hideSearchDialog"
            >
              {{ suggestion }}
            </NuxtLink>
          </div>
          <div v-else class="mt-32">
            <div v-if="isLoading" class="m-48">
              <NxLoader />
            </div>

            <div
              v-for="editorialEntry in editorialEntries"
              :key="editorialEntry.id"
            >
              <h2 class="headline--small mb-8">
                {{ editorialEntry.title }}
              </h2>
              <div class="row">
                <div
                  v-for="(book, index) in editorialEntry.products"
                  :key="book.id"
                  class="col-6 col-md-4 col-lg-2"
                >
                  <CatalogBookCard
                    :book="book"
                    theme="dark"
                    :index="index"
                    @click="hideSearchDialog"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { useFocusTrap } from '~/composables/useFocusTrap'

export default defineNuxtComponent({
  setup() {
    const { verticalHeightCssVar } = usePageFullHeight()
    const { focusTrap } = useFocusTrap()

    return {
      verticalHeightCssVar,
      focusTrap,
    }
  },

  props: {
    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    darkTheme: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isSearchExpanded: false,
      search: '',
      suggestions: [],
      currentlySearching: undefined, // string representing the current search term (used to avoid race conditions in the debounced function)
      editorialEntries: undefined, // must be initialized as undefined and not an empty array, to know if data has been fetched or not (even if empty)
      isLoading: false,
    }
  },

  beforeMount() {
    // prefill with currently search term, if any
    this.search = this.$route.query.q || ''
  },

  mounted() {
    this.observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          window.addEventListener('focusin', this.close, false)
          window.addEventListener('keydown', this.close, false)
        } else {
          window.removeEventListener('focusin', this.close, false)
          window.removeEventListener('keydown', this.close, false)
        }
      },
      { threshold: 0 }
    )

    this.observer?.observe(this.$refs.searchDialog)
  },

  beforeUnmount() {
    // Making sure body never keeps the overflow-hidden class when switching pages
    this.$store.dispatch('setAsFullscreenComponent', false)

    this.observer?.disconnect()
  },

  methods: {
    handleSearch() {
      this.$router.push(
        this.localePath({
          name: 'search',
          query: { q: this.search },
        })
      )

      this.hideSearchDialog()
    },

    resetSearch() {
      this.search = ''
      this.suggestions = []
      this.$refs.search.focus()
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.hideSearchDialog()
      }

      if (e.key === 'Escape') {
        this.hideSearchDialog()
      }

      // Keyboard focus trap inside the dialog
      if (e.key === 'Tab') {
        const dialog = this.$refs.searchDialog
        this.focusTrap(dialog, e)
      }
    },

    showSearchDialog() {
      this.isSearchExpanded = true

      if (!this.editorialEntries) {
        // This is the first time search is opened, so we fetch the editorial entries (only once, though)
        this.fetchEditorialEntries()
      }

      this.$nextTick(() => {
        this.$refs.search.focus()

        // JS tricks: Putting cursor at end of input
        this.$refs.search.value = ''
        this.$refs.search.value = this.search

        if (this.search) {
          // Force update of suggestions
          this.updateSuggestions()
        }
      })

      this.$store.dispatch('setAsFullscreenComponent', true)
    },

    hideSearchDialog() {
      this.isSearchExpanded = false

      this.$store.dispatch('setAsFullscreenComponent', false)
      // A11Y: Focus back to dialog trigger button
      this.$refs.searchButton.focus()
    },

    updateSuggestions: debounce(async function () {
      const search = this.search.trim()
      if (search.length === 0) {
        this.suggestions = []

        return
      }

      this.currentlySearching = search
      const suggestions = await this.$hummingbird.catalog
        .autocomplete(search)
        .then(res => getMostPertinentSuggestions(res.data, search, 7))
        .catch(() => [])

      if (this.currentlySearching === search) {
        // This check is necessary to avoid race conditions due to debouncing
        // (e.g. an older API request responding after a newer one)
        this.suggestions = suggestions
      }
    }, 500),

    async fetchEditorialEntries() {
      this.isLoading = true

      this.editorialEntries = await fetchEditorialEntries()

      this.isLoading = false
    },
  },
})
</script>

<style lang="scss">
.fullscreen-navigation {
  position: fixed;
  inset: 0;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  // height: managed by .is-fullheight
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.header__input {
  // .header__input-wrapper
  &-wrapper {
    border-bottom: 3px solid $purple-500;
  }

  // .header__input-field
  &-field {
    background-color: transparent;
    border: none;
    outline: none;

    &::placeholder {
      color: inherit;
    }

    // we reducing the font size for smallest devices to
    // avoid the text in the input field to be cut off.
    @include media-breakpoint-down(sm) {
      font-size: 2.4rem;
    }
  }

  // .header__input-reset
  &-reset {
    margin: 0;
    padding: 0;
    color: $white;
    background-color: transparent;
    border: none;

    &:hover {
      color: $purple-300;
    }
  }
}

.search__suggestion {
  // truncate text, so it's never more than a single line
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: padding 150ms;

  &:hover {
    color: $purple-300;
  }

  &:focus-visible {
    padding: 0 1rem;
    background-color: $purple-800;
    border: 0.2rem solid $purple-500;
    border-radius: 0.5rem;
  }
}
</style>
