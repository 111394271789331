export const isUnset = (o: unknown): boolean =>
  typeof o === 'undefined' || o === null

export const isSet = (o: unknown): boolean => !isUnset(o)

export const isSameURL = (ctx: any, a: string, b: string): boolean =>
  normalizePath(a, ctx) === normalizePath(b, ctx)

export function normalizePath(path = '', ctx?: any): string {
  // Remove query string
  let result = path.split('?')[0]

  // Remove base path
  if (ctx && ctx.base) {
    result = result.replace(ctx.base, '/')
  }

  // Remove redundant / from the end of path
  if (result.charAt(result.length - 1) === '/') {
    result = result.slice(0, -1)
  }

  // Remove duplicate slashes
  result = result.replace(/\/+/g, '/')

  return result
}

export function encodeValue(val: unknown): string {
  if (typeof val === 'string') {
    return val
  }

  return JSON.stringify(val)
}

export function decodeValue(val: unknown): unknown {
  // Try to parse as json
  if (typeof val === 'string') {
    try {
      return JSON.parse(val)
    } catch {
      // Do nothing
    }
  }

  // Return as is
  return val
}

/**
 * Get property defined by dot notation in string.
 * Based on  https://github.com/dy/dotprop (MIT)
 *
 * @param  {Object} holder   Target object where to look property up
 * @param  {string} propName Dot notation, like 'this.a.b.c'
 * @return {*}          A property value
 */
export function getProp(
  holder: Record<string, any>,
  propName: string | false
): unknown {
  if (!propName || !holder || typeof holder !== 'object') {
    return holder
  }

  if (propName in holder) {
    return holder[propName]
  }

  const propParts = Array.isArray(propName)
    ? propName
    : String(propName).split('.')

  let result: unknown = holder
  while (propParts.length && result) {
    result = (result as Record<string, any>)[propParts.shift()!]
  }

  return result
}
