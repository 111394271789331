import type { CookieSerializeOptions } from 'cookie-es'
import { parse, serialize } from 'cookie-es'
import type { NuxtApp } from 'nuxt/app'
import { decodeValue, encodeValue, getProp, isSet, isUnset } from './utils'

const STORE_NAMESPACE = 'auth'
const STORAGE_PREFIX = STORE_NAMESPACE + '.'
const COOKIE_OPTIONS = {
  domain: process.env.NUXT_PUBLIC_SITE_URL?.replace(/^https?:\/\//, ''),
  expires: 30 /* days */,
} as Omit<CookieSerializeOptions, 'expires'> & {
  expires: Date | number | undefined
}

export default class Storage {
  public state: any
  private domain: string

  constructor(
    public store: any,
    public ctx: NuxtApp
  ) {
    this.ctx = ctx
    this.state = this.store.state[STORE_NAMESPACE]

    // Get domain from runtime config
    const {
      public: { NEXTORY_WEB_URL },
    } = useRuntimeConfig()
    const url = new URL(NEXTORY_WEB_URL)
    this.domain = url.hostname
  }

  // ------------------------------------
  // Universal
  // ------------------------------------

  setUniversal<V>(key: string, value: V): V | void {
    // Unset null, undefined
    if (isUnset(value)) {
      return this.removeUniversal(key)
    }

    // Cookies
    this.setCookie(key, value)

    // Local Storage
    this.setLocalStorage(key, value)

    // Local state
    this.setState(key, value)

    return value
  }

  getUniversal(key: string): unknown {
    let value

    // Local state
    if (import.meta.server) {
      value = this.getState(key)
    }

    // Cookies
    if (isUnset(value)) {
      value = this.getCookie(key)
    }

    // Local Storage
    if (isUnset(value)) {
      value = this.getLocalStorage(key)
    }

    // Local state
    if (isUnset(value)) {
      value = this.getState(key)
    }

    return value
  }

  syncUniversal(key: string, defaultValue?: unknown): unknown {
    let value = this.getUniversal(key)

    if (isUnset(value) && isSet(defaultValue)) {
      value = defaultValue
    }

    if (isSet(value)) {
      this.setUniversal(key, value)
    }

    return value
  }

  removeUniversal(key: string): void {
    this.removeState(key)
    this.removeLocalStorage(key)
    this.removeCookie(key)
  }

  // ------------------------------------
  // Local state (reactive)
  // ------------------------------------

  setState(key: string, value: unknown) {
    this.store.commit('SET_AUTH', {
      key,
      value,
    })

    return value
  }

  getState(key: string): unknown {
    return this.state[key]
  }

  watchState(
    key: string,
    fn: (value: unknown, oldValue: unknown) => void
  ): () => void {
    return this.store.watch(
      (state: any) => getProp(state[STORE_NAMESPACE], key),
      fn
    )
  }

  removeState(key: string): void {
    this.setState(key, undefined)
  }

  // ------------------------------------
  // Local storage
  // ------------------------------------

  setLocalStorage(key: string, value: unknown): unknown | void {
    // Unset null, undefined
    if (isUnset(value)) {
      return this.removeLocalStorage(key)
    }

    if (!this.isLocalStorageEnabled()) {
      return
    }

    const _key = this.getPrefix() + key

    localStorage.setItem(_key, encodeValue(value))

    return value
  }

  getLocalStorage(key: string): unknown {
    if (!this.isLocalStorageEnabled()) {
      return
    }

    const _key = this.getPrefix() + key

    const value = localStorage.getItem(_key)

    return decodeValue(value)
  }

  removeLocalStorage(key: string): void {
    if (!this.isLocalStorageEnabled()) {
      return
    }

    const _key = this.getPrefix() + key

    localStorage.removeItem(_key)
  }

  // ------------------------------------
  // Cookies
  // ------------------------------------
  getCookies(): Record<string, unknown> {
    if (!this.isCookiesEnabled()) {
      return {}
    }
    const cookieStr = import.meta.client
      ? document.cookie
      : useRequestHeaders(['cookie']).cookie

    return parse(cookieStr || '') || {}
  }

  setCookie(key: string, value: unknown, options: { prefix?: string } = {}) {
    if (import.meta.server && !this.ctx.res) {
      return value
    }

    if (!this.isCookiesEnabled()) {
      return value
    }

    const _key = STORAGE_PREFIX + key
    const _options = Object.assign({}, COOKIE_OPTIONS, options)
    const _value = encodeValue(value)

    _options.path = '/'
    _options.domain = (this.domain === 'localhost' ? '' : '.') + this.domain

    // Unset null, undefined
    if (isUnset(value)) {
      _options.maxAge = -1
    }

    // Accept expires as a number for js-cookie compatibility
    if (typeof _options.expires === 'number') {
      _options.expires = new Date(Date.now() + _options.expires * 864e5)
    }

    // We have to tell TS that expires is a Date|undefined now, number have been checked already
    const _optionsWithDate = _options as CookieSerializeOptions & {
      expires: Date | undefined
    }

    const serializedCookie = serialize(_key, _value, _optionsWithDate)

    if (import.meta.client) {
      // Set in browser
      document.cookie = serializedCookie
    } else if (import.meta.server) {
      // Setting a cookie is not supported on server side.
      // eslint-disable-next-line no-console
      console.error('Error: Setting cookies on server side is not supported.')
    }

    return value
  }

  getCookie(key: string): unknown {
    if (!this.isCookiesEnabled()) {
      return
    }

    const _key = STORAGE_PREFIX + key

    const cookies = this.getCookies()

    const value = cookies[_key]
      ? decodeURIComponent(cookies[_key] as string)
      : undefined

    return decodeValue(value)
  }

  removeCookie(key: string, options?: { prefix?: string }): void {
    this.setCookie(key, undefined, options)
  }

  getPrefix(): string {
    return STORAGE_PREFIX
  }

  isLocalStorageEnabled(): boolean {
    // Local Storage only exists in the browser
    if (!import.meta.client) {
      return false
    }

    // There's no great way to check if localStorage is enabled; most solutions
    // error out. So have to use this hacky approach :\
    // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
    const test = 'test'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)

      return true
    } catch {
      return false
    }
  }

  isCookiesEnabled(): boolean {
    // Server can only assume cookies are enabled, it's up to the client browser
    // to create them or not
    if (import.meta.server) {
      return true
    }

    if (window.navigator.cookieEnabled) {
      return true
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        "[AUTH] Cookies is enabled in config, but browser doesn't" +
          ' support it'
      )

      return false
    }
  }
}
