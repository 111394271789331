<template>
  <img
    key="image"
    ref="image"
    class="responsive-img"
    :alt="alt"
    :width="width"
    :height="height"
    :srcset="ixSrcset"
    :src="ixSrc"
    :loading="lazy ? 'lazy' : 'eager'"
  />
</template>

<script>
export default defineNuxtComponent({
  props: {
    height: { type: [String, Number], default: '128' },
    width: { type: [String, Number], default: '128' },
    src: { type: String, required: true },
    alt: { type: String, required: true },
    lazy: { type: Boolean, default: true },
  },

  setup(props) {
    // If we have an absolute URL, we won't be able to use the ImgixClient, so we'll just use the src as is.
    if (props.src.startsWith('http')) {
      return {
        ixSrcset: null,
        ixSrc: props.src,
      }
    }

    const ixClient = useImgix()

    const options = {
      w: props.width,
      h: props.height,
      auto: 'format',
    }
    const minMaxWidth = {
      minWidth: Math.min(Number(props.width), 320),
      maxWidth: Number(props.width),
    }
    const ixSrcset = ixClient.buildSrcSet(props.src, options, minMaxWidth)
    const ixSrc = ixClient.buildURL(props.src, options, minMaxWidth)
    const [protocol, , host] = ixSrc.split('/')
    const ixHostWithProtocol = `${protocol}//${host}`

    // Preconnect to the Imgix domain
    useHead({
      link: [
        {
          rel: 'preconnect',
          href: ixHostWithProtocol,
        },
      ],
    })

    return {
      ixSrcset,
      ixSrc,
    }
  },

  data() {
    return {
      imgLoad: false,
    }
  },
})
</script>
