import { createStore } from 'vuex'

const state = () => ({
  deviceId: null,
  loggedIn: false,
  token: null,
  user: null,
})

const mutations = {
  SET_AUTH(state, payload) {
    state[payload.key] = payload.value
  },
}

export default {
  state,
  mutations,
}

export const useAuth = createStore({
  state,
  mutations,
})
