import type { AxiosPromise } from 'axios'

import type { Profile as LegacyProfile } from '../legacy-api/types/profile'

import BaseApi from './baseApi'
import { modernAccountToLegacyProfile } from './converters/profileToLegacyProfile'
import convert from './helpers/convert'

export interface PasswordLoginOptions {
  identifier: string
  password: string
}

export interface SSOLoginOptions {
  access_token: string
  provider:
    | 'digital_virgo'
    | 'facebook'
    | 'free'
    | 'google'
    | 'sfr'
    | 'vitis'
    | 'external_token'
}

export interface AppleLoginOptions {
  access_token: string
  provider: 'apple'
  user?: {
    accept_newsletter?: boolean
    email?: string
    first_name?: string
    last_name?: string
    username?: string
  }
}

// LoginOptions is a union of all login options
export type LoginOptions =
  | PasswordLoginOptions
  | SSOLoginOptions
  | AppleLoginOptions

export default class Sessions extends BaseApi {
  login(data: LoginOptions): AxiosPromise<LegacyProfile> {
    return convert(
      this.http({
        method: 'post',
        url: `/user/v1/sessions`,
        data,
      }),
      modernAccountToLegacyProfile
    )
  }

  logout() {
    return this.http({
      method: 'delete',
      url: `/user/v1/sessions`,
    })
  }
}
