import Hummingbird from './hummingbird/core'

// Note: The number in the filename is the order in which the plugin is loaded.
// As per Nuxt recommendation: https://nuxt.com/docs/guide/directory-structure/plugins#plugin-registration-order

export default defineNuxtPlugin({
  name: 'hummingbird',
  setup({ $i18n, provide }) {
    const sandboxHeadersState =
      useState<Record<string, string>>('sandboxHeaders')
    const headers = useRequestHeaders()
    const host = headers.host || window.location.host
    const { NEXTORY_API_ENDPOINT, SANDBOX_HEADERS_PREFIX } =
      useRuntimeConfig().public

    const sandboxHeaders = Object.fromEntries(
      Object.entries(headers).filter(([key]) =>
        key.startsWith(SANDBOX_HEADERS_PREFIX.toLowerCase())
      )
    )

    if (Object.entries(sandboxHeaders).length > 0) {
      sandboxHeadersState.value = sandboxHeaders
    }

    provide(
      'hummingbird',
      new Hummingbird(
        host,
        NEXTORY_API_ENDPOINT,
        $i18n.localeProperties.value.language,
        sandboxHeadersState.value
      )
    )
  },
})
