import type { AxiosPromise } from 'axios'

import type {
  EditableProfile,
  ProfilesResponse,
  Profile,
} from '../api-types/Profile'
import BaseApi from '../bridge-api/baseApi'

export class Profiles extends BaseApi {
  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2062778374/User+API+Spec#1.0.5-List-Profiles
   */
  getProfiles(): AxiosPromise<ProfilesResponse> {
    return this.http({
      method: 'get',
      url: `/user/v1/me/profiles`,
    })
  }

  /**
   * Authorize profile: gives access to books
   */
  authorizeProfile(loginKey: string): AxiosPromise<Profile> {
    return this.http({
      method: 'post',
      url: `/user/v1/profile/authorize`,
      data: {
        login_key: loginKey,
      },
    })
  }

  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2062778374/User+API+Spec#1.0.6-Add-Profile
   */
  addProfile(data: EditableProfile): AxiosPromise<any> {
    return this.http({
      method: 'post',
      url: `/user/v1/me/profile`,
      data,
    })
  }

  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2062778374/User+API+Spec#1.0.7-Update-Profile
   */
  updateProfile(data: EditableProfile): AxiosPromise<any> {
    return this.http({
      method: 'patch',
      url: `/user/v1/me/profile`,
      data,
    })
  }

  /**
   * @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2062778374/User+API+Spec#1.0.8-Delete-Profile
   */
  deleteProfile(loginKey: string): AxiosPromise<any> {
    return this.http({
      method: 'delete',
      url: `/user/v1/me/profile`,
      data: {
        login_key: loginKey,
      },
    })
  }
}
