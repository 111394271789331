import { createStore } from 'vuex'
import accountProfiles from './accountProfiles'
import auth from './auth'
import flash from './flash'
import flashMessage from './flashMessage'
import layout from './layout'
import opportunities from './opportunities'
import payment from './payment'
import steps from './steps'
import webConfig from './webConfig'

const modules = {
  accountProfiles,
  auth,
  flash,
  flashMessage,
  layout,
  opportunities,
  payment,
  steps,
  webConfig,
}

// TODO: Move to Pinia 🍍 Check ~/plugins/vuex.ts for more information.
export default function initStore() {
  return createStore({
    modules,
  })
}
