import { createStore } from 'vuex'

const state = () => ({
  success: null,
  danger: null,
  info: null,
})

const getters = {
  getFlashes(state) {
    return state
  },
}

const mutations = {
  SET_SIMPLE_FLASH(state, { message, variant }) {
    state[variant] = message
  },

  RESET_SIMPLE_FLASHES(state) {
    state.success = null
    state.danger = null
    state.info = null
  },
}

const actions = {
  setFlashMessage({ commit }, { message, variant }) {
    commit('SET_SIMPLE_FLASH', { message, variant })
  },

  resetAllFlashMessages({ commit }) {
    commit('RESET_SIMPLE_FLASHES')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}

export const useFlash = createStore({
  state,
  getters,
  mutations,
  actions,
})
