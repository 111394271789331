import type { Account } from '../../api-types/Account'
import type { Profile as LegacyProfile } from '../../legacy-api/types/profile'

export function modernAccountToLegacyProfile(profile: Account): LegacyProfile {
  return {
    accept_newsletter: profile.accept_newsletter,
    accept_push: profile.accept_push,
    authentication_token: profile.login_token,
    birthday: null,
    catalog: 0,
    centers_of_interest: [],
    country: profile.country,
    credit_system: profile.user_type === 'MEMBER' ? 'time' : 'none',
    credits: 0,
    email: profile.email,
    emailable: profile.emailable,
    first_name: profile.first_name,
    gender: 'unspecified',
    id: profile.id,
    is_adult_filtered: true, // Can't be made dynamic for now, on NX5 it's from the Profile
    is_premium: ['MEMBER', 'GIFTCARD'].includes(profile.user_type),
    is_non_member: profile.user_type === 'NONMEMBER', // FIXME: Quick hack, but we should remove the legacy profile entirely
    languages: [],
    last_name: profile.last_name,
    mobile_offers: [],
    onboarded: false,
    parental_control_password: null,
    phone_number: profile.phone_number,
    pseudo: null,
    recommendation_segment: null,
    refresh_token: null,
    remaining_reading_time: 0, // TODO: Make dynamic
    sign_in_count: profile.sign_in_count,
    virtual_catalogs: [],
  }
}
