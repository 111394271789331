import type {
  Book,
  BorrowAction,
  CreativeWorkSeries,
  Offer,
  Organization,
  Person,
  Product as ProductSchema,
} from 'schema-dts'
import {
  getLocalizedPrice,
  secondsToDurationString,
  parseLocaleIso,
} from './localizationFormatter'
import { createSlug } from './slug'
import type {
  Comment,
  Format,
  GenericName,
  Product,
} from '~/plugins/hummingbird/api-types/CatalogProduct'

// Helper function to create meta elements for the `head()` of Nuxt pages
export function metaElements(
  title: string,
  description?: string,
  image?: string
) {
  if (image) {
    image = image?.replace(
      '{$width}',
      '630' // recommended max width for OpenGraph images
    )
  }

  const imageMetaElements = image
    ? [
        {
          hid: 'og:image:secure_url',
          property: 'og:image:secure_url',
          content: image,
        },
        {
          property: 'twitter:image',
          content: image,
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
      ]
    : []

  const descriptionMetaElements = description
    ? [
        {
          hid: 'og:description',
          property: 'og:description',
          content: description,
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
      ]
    : []

  return {
    title,
    meta: [
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      ...descriptionMetaElements,
      ...imageMetaElements,
    ],
  }
}

export function getRecommendedImageFromProducts(products: Product[]) {
  const allFormats = products.flatMap(product => product.formats)

  return getRecommendedCoverFromFormats(allFormats)?.img_url
}

export function getRecommendedCoverFromFormats(
  formats: Product['formats']
): Format | undefined {
  if (formats.length === 0) {
    return undefined
  }

  // We prefer square images
  let format = formats.find(format => format.cover_ratio === 1)
  if (!format) {
    // If there are no square images, fallback to the first format available
    format = formats[0]
  }

  return format
}

export function getStructuredOffer(ctx: any): Offer {
  const { $i18n, $link, $localePath, $store } = ctx

  const minPrice = $store.state.webConfig.config.minimumPrice
  const maxPrice = $store.state.webConfig.config.maximumPrice

  const planFormUrl = $link.absolute(
    $localePath({
      name: 'register-planform',
    })
  )

  return {
    '@type': 'Offer',
    url: planFormUrl,
    priceSpecification: {
      '@type': 'PriceSpecification',
      price: 0, // we've got a free trial
      maxPrice: maxPrice.amount,
      priceCurrency: maxPrice.currency,
      valueAddedTaxIncluded: true,
    },
    description: $i18n
      .t(
        $store.state.webConfig.config.features.hasMagazines
          ? 'homepage.hero.body.magazine_market'
          : 'homepage.hero.body',
        {
          price: getLocalizedPrice(minPrice.amount, minPrice.currency, ctx),
        }
      )
      .toString(),
    availability: 'https://schema.org/InStock',
  }
}

export function getStructuredBreadcrumb(
  ctx: any,
  breadcrumb: Array<{ href: string; name: string }>
) {
  if (!breadcrumb) return {}

  const { $link } = ctx

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListOrder: 'Ascending',
    itemListElement: breadcrumb.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.name,
      item: $link.absolute(item.href),
    })),
  }
}

export function getStructuredSearchAction({ $link, $localePath }: any) {
  const searchUrl = $link.absolute(
    $localePath({
      name: 'search',
    })
  )

  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': 'urn:nextory:search',
    url: $link.absolute(
      $localePath({
        name: 'index',
      })
    ),
    potentialAction: {
      '@type': 'SearchAction',
      target: `${searchUrl}?q={search_term}`,
      'query-input': 'required name=search_term',
    },
  }
}

export function getStructuredPerson(
  { $link, $localePath }: any,
  persons: GenericName[],
  pageName: string,
  type: 'Author' | 'Narrator'
): Person[] {
  return persons.map(person => ({
    '@type': 'Person',
    '@id': `urn:nextory:${type.toLowerCase()}:${person.id}`,
    name: person.name,
    url: $link.absolute(
      $localePath({
        name: pageName,
        params: {
          slug: createSlug(person.name, person.id),
        },
      })
    ),
  }))
}

export function getStructuredOrganization(
  name: string,
  id: number | string,
  type: string
): Organization {
  return {
    // @ts-ignore
    '@context': 'https://schema.org',
    // @ts-ignore
    '@type': 'Organization',
    '@id': `urn:nextory:${type.toLowerCase()}:${id}`,
    name,
  }
}

export function getStructuredSeries(
  { $link, $localePath }: any,
  book: Product
): CreativeWorkSeries | undefined {
  if (!book.series) return undefined

  const seriesUrl = $link.absolute(
    $localePath({
      name: 'series-slug',
      params: {
        slug: createSlug(book.series.name, book.series.id),
      },
    })
  )

  return {
    '@type': 'CreativeWorkSeries',
    '@id': `urn:nextory:series:${book.series.id}`,
    name: book.series.name,
    hasPart: {
      '@type': 'Book',
      position: book.volume,
    },
    url: seriesUrl,
  }
}

export function getStructuredPotentialAction(
  { $link, $localePath }: any,
  name: string,
  isbn: string
): BorrowAction {
  const planFormUrl = $link.absolute(
    $localePath({
      name: 'register-planform',
    })
  )

  return {
    // Tell that the book can be read on mobile devices
    '@type': 'BorrowAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: planFormUrl,
      actionPlatform: [
        // 'https/DesktopWebPlatform', <-- uncommented when webreader is developed 😉
        'https://schema.org/IOSPlatform',
        'https://schema.org/AndroidPlatform',
      ],
    },
    result: {
      '@type': 'Book',
      name,
      isbn,
    },
    lender: {
      '@type': 'LibrarySystem',
      name: 'Nextory',
      '@id': 'https://nextory.com/',
    },
  }
}

// Note: If the book doesn't have an author, but rather, one or more contributors,
// include these other contributors as defined in schema.org/Book.
//
// Conversely, if a book's author is an organization, define the author accordingly
// as defined in schema.org/Organization.
export function getStructuredBookAuthor(
  ctx: any,
  book: Product
): Person[] | Organization | undefined {
  if (book.authors.length === 0) {
    const publisher = book.formats[0]?.publisher
    if (!publisher) return undefined

    return getStructuredOrganization(publisher.name, publisher.id, 'publisher')
  } else {
    return getStructuredPerson(ctx, book.authors, 'author-slug', 'Author')
  }
}

export function getStructuredBookEntity(
  ctx: any,
  book: Product,
  productCategories: []
): Book | ProductSchema {
  const { $link, $localePath } = ctx

  const bookUrl = $link.absolute(
    $localePath({
      name: 'book-slug',
      params: {
        slug: createSlug(book.title, book.id),
      },
    })
  )

  return {
    '@id': `urn:nextory:book-id:${book.id}`,
    '@type': 'Book',
    author: getStructuredBookAuthor(ctx, book),
    name: book.title,
    url: bookUrl,
    description: book.description_full,
    // "sameAs": Add later when more information in DB
    // "aggregateRating": Fix this issue first: Review has multiple aggregate ratings
    // "review" : Fix this issue first: Review has multiple aggregate ratings
    offers: getStructuredOffer(ctx),
    genre: getLastLevelTitles(productCategories),
    isPartOf: getStructuredSeries(ctx, book),
    isFamilyFriendly: !book.is_adult_book,
  }
}

export function getStructuredBookEdition(ctx: any, book: Product): Book[] {
  const { $link, $localePath } = ctx

  const bookUrl = $link.absolute(
    $localePath({
      name: 'book-slug',
      params: {
        slug: createSlug(book.title, book.id),
      },
    })
  )

  // Book formats - Generate each format available for the current book (ePub, PDF, audio, ...)
  return book.formats.map((format: Format): Book => {
    let additionalProperties

    if (format.type === 'hls') {
      additionalProperties = {
        duration: secondsToDurationString(format.duration),
        readBy: getStructuredPerson(
          ctx,
          book.narrators,
          'narrator-slug',
          'Narrator'
        ),
      }
    }

    return {
      '@id': `urn:isbn:${format.isbn}`,
      '@type': format.type === 'hls' ? 'Audiobook' : 'Book',
      bookFormat:
        format.type === 'hls'
          ? 'https://schema.org/AudiobookFormat'
          : 'https://schema.org/EBook',
      inLanguage: book.language,
      isbn: format.isbn,
      potentialAction: getStructuredPotentialAction(
        ctx,
        book.title,
        format.isbn
      ),
      // author: Set only if different from BookEntity not the case here
      // bookEdition: Add later when more information in DB
      datePublished: format.publication_date,
      // identifier: Add later when more information in DB
      // sameAs: Add later when more information in DB
      name: book.title,
      url: bookUrl,
      image: format.img_url.replace('{$width}', '200'), // don't use bookCoverUrl, since it's not the same for all formats
      publisher: {
        '@type': 'Organization',
        name: format.publisher.name,
      },
      ...additionalProperties,
    }
  })
}

export function getStructuredBooks(
  ctx: any,
  book: Product,
  comments: Comment[],
  productCategories: []
): Book {
  return {
    // @ts-ignore
    '@context': 'https://schema.org',
    ...getStructuredBookEntity(ctx, book, productCategories),
    workExample: getStructuredBookEdition(ctx, book),
  }
}

export function getNextoryOrganization(ctx: any) {
  const { $link, $localePath } = ctx

  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Nextory',
    url: $link.absolute(
      $localePath({
        name: 'index',
      })
    ),
    logo: `${process.env.NEXTORY_WEB_URL}logo-nextory.png`,
    sameAs: [
      'https://www.facebook.com/nextory/',
      'https://www.linkedin.com/company/nextory',
      'https://www.youtube.com/channel/UC2IqgcVr3n-ekah1S1w7hHw/',
      'https://www.pinterest.se/nextorycom/',
    ],
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'SE', // Need to be in ISO 3166-2
      addressLocality: 'Stockholm',
      streetAddress: 'Norrtullsgatan 6',
      postalCode: '11329',
    },
  }
}

// Extracts the titles of the last-level sub-categories from the provided array
function getLastLevelTitles(categories: Array<[]>): string[] {
  interface CategoryList {
    id: number
    slug: string
    title: string
  }

  if (!categories) return ['Unknown']

  return categories.map((category: CategoryList[]) => {
    const categoryTitle = category[category.length - 1]?.title

    return categoryTitle || 'Unknown'
  })
}

/**
 * By default, Nuxt i18n will generate hreflang links for all available languages in the project.
 *
 * However, in some cases, we want to limit the hreflang links to only the languages where the page is available.
 *
 * Example: A book that can be only be read in Belgium should only have hreflang links for the languages available in Belgium: fr, fr-BE, en, en-BE.
 * ➡ https://nextory.com/be-en/book/vive-leducation-3664564
 *
 * To do this, we have to rewrite the existing links by using the same HID.
 *
 * Before
 * ------
 *
 * <link data-n-head="ssr" data-hid="i18n-alt-sv" rel="alternate" href="https://nextory.com/se/book/vive-leducation-3664564" hreflang="sv">
 * <link data-n-head="ssr" data-hid="i18n-alt-sv-SE" rel="alternate" href="https://nextory.com/se/book/vive-leducation-3664564" hreflang="sv-SE">
 * <link data-n-head="ssr" data-hid="i18n-alt-en" rel="alternate" href="https://nextory.com/se-en/book/vive-leducation-3664564" hreflang="en">
 * <link data-n-head="ssr" data-hid="i18n-alt-en-SE" rel="alternate" href="https://nextory.com/se-en/book/vive-leducation-3664564" hreflang="en-SE">
 * <link data-n-head="ssr" data-hid="i18n-alt-fr" rel="alternate" href="https://nextory.com/fr/book/vive-leducation-3664564" hreflang="fr">
 * <link data-n-head="ssr" data-hid="i18n-alt-fr-FR" rel="alternate" href="https://nextory.com/fr/book/vive-leducation-3664564" hreflang="fr-FR">
 * <link data-n-head="ssr" data-hid="i18n-alt-en-FR" rel="alternate" href="https://nextory.com/fr-en/book/vive-leducation-3664564" hreflang="en-FR">
 * ...
 * This continues for all our locales, pointing to links that don't exist.
 *
 * After
 * -----
 *
 * <link data-n-head="ssr" data-hid="i18n-alt-en" rel="alternate" href="https://nextory.com/be-en/book/vive-leducation-3664564" hreflang="en">
 * <link data-n-head="ssr" data-hid="i18n-alt-en-BE" rel="alternate" href="https://nextory.com/be-en/book/vive-leducation-3664564" hreflang="en-BE">
 * <link data-n-head="ssr" data-hid="i18n-alt-fr" rel="alternate" href="https://nextory.com/be/book/vive-leducation-3664564" hreflang="fr">
 * <link data-n-head="ssr" data-hid="i18n-alt-fr-BE" rel="alternate" href="https://nextory.com/be/book/vive-leducation-3664564" hreflang="fr-BE">
 * The rest are removed.
 *
 * We also update the x-default link, which is the first English link or the first link available.
 *
 * The logic is as follows:
 * 1. Initialize a locale map with null values for each language and locale.
 * 2. Iterate over the available countries and the locales in the Nuxt config.
 * 3. If the country is available, and the language is not already set, set the language and locale to the current path.
 * 4. Generate the hreflang links based on the locale map.
 * 5. Add the x-default link, which is the first English link or the first link available.
 * 6. Done! 🎉
 */
export function getHrefLangsByCountryAvailability(
  ctx: any,
  availableCountries: string[], // List of country codes where the page is available, e.g. ['SE', 'NO', 'FI']
  path: any
) {
  const { $i18n, $link, $localePath } = ctx
  const locales = $i18n.locales.value as Array<{ code: string; iso: string }>
  const localeMap = {} as Record<string, string | null>

  for (const locale of locales) {
    // @ts-ignore
    const { country, language } = parseLocaleIso(locale.language)

    // Initialize the locale map with null values, if not already set
    localeMap[language] = localeMap[language] || null
    localeMap[locale.language] = localeMap[locale.language] || null

    if (availableCountries.includes(country)) {
      // The null checks are here to help with language prioritization (we follow the order of the locales in the Nuxt config)
      if (localeMap[language] === null) {
        localeMap[language] =
          localeMap[language] || $localePath(path, locale.code)
      }
      if (localeMap[locale.language] === null) {
        localeMap[locale.language] =
          localeMap[locale.language] || $localePath(path, locale.code)
      }
    }
  }

  const hrefLangs = []

  for (const [locale, href] of Object.entries(localeMap)) {
    if (href) {
      hrefLangs.push({
        key: `i18n-alt-${locale}`,
        rel: 'alternate',
        href: $link.absolute(href),
        hreflang: locale,
      })
    } else {
      hrefLangs.push({
        // We remove the existing tags: https://unhead.unjs.io/usage/guides/handling-duplicates#removing-a-nested-tag
        key: `i18n-alt-${locale}`,
      })
    }
  }

  // Now, we need to add the x-default link. By default, we use the first english link, or the first link as a fallback.
  hrefLangs.push({
    key: 'i18n-xd',
    rel: 'alternate',
    href:
      hrefLangs.find(link => link.hreflang?.startsWith('en'))?.href ||
      hrefLangs[0]?.href,
    hreflang: 'x-default',
  })

  return hrefLangs
}
