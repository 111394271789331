import type { AxiosPromise } from 'axios'

import BaseApi from '../bridge-api/baseApi'

export class Partner extends BaseApi {
  validatePartnerMember(
    channelName: string,
    data: { email: string; external_id?: string }
  ): AxiosPromise<{
    action: 'login' | 'register'
    code: string
    utm_params: string
  }> {
    return this.http({
      method: 'POST',
      url: `/obgateway/v1/partner/${channelName}/validate`,
      data,
    })
  }

  subscribeToPartnerLoyalty(data: {
    channel: string
    external_id: string
  }): AxiosPromise {
    return this.http({
      method: 'PUT',
      url: '/obgateway/v1/partner/loyalty/subscribe',
      data,
    })
  }

  getPartnerLoyaltyProviders(): AxiosPromise<
    {
      channel: string
      created_on: string
      id: number
      is_subscribed: boolean
      loyalty_points_act: number
      loyalty_points_renew: number
      mode: 'R' | 'RW' // R = read-only and user is not allowed to subscribe or unsubscribe. RW = allowed to subscribe/unsubscribe
      total_points: number
    }[]
  > {
    return this.http({
      method: 'GET',
      url: '/obgateway/v1/partner/loyalty',
    }).then(response => {
      // FIXME: In the response, KJELL channel is present twice. We need to remove the second occurrence only, if needed, and leave the rest as is.
      // This temporary code needs to be removed once the backend is fixed.
      // https://nextory.atlassian.net/browse/ACQ-1953

      const data = response.data
      const seenChannels = new Set<string>()
      const filteredData = data.filter((item: any) => {
        if (item.channel === 'KJELL' && seenChannels.has('KJELL')) {
          return false
        }
        seenChannels.add(item.channel)

        return true
      })

      return {
        ...response,
        data: filteredData,
      }
    })
  }

  unsubscribeFromPartnerLoyalty(channel: string): AxiosPromise<{}> {
    return this.http({
      method: 'DELETE',
      url: '/obgateway/v1/partner/loyalty/unsubscribe',
      data: {
        channel,
      },
    })
  }
}
