/**
 * This middleware redirects logged-in users
 * to their corresponding country/market based
 * on their country and preferred language.
 *
 * Logged-out users are allowed to see all markets.
 */
export default defineNuxtRouteMiddleware(to => {
  const { $auth, $i18n } = useNuxtApp()

  if (!$auth.loggedIn || to.fullPath === '/') {
    // Not logged in, allowed to see all markets
    return
  }

  // Temporarily disabled, since -lk locales are not supported yet on Nuxt 3 due to performance issues with too many locales
  // const currentCountry = $i18n.localeProperties.value.language
  //   .split('-')[1]
  //   .toLowerCase()
  // if (
  //   process.env.NODE_ENV !== 'production' &&
  //   to.fullPath.startsWith(`/${currentCountry}-lk`)
  // ) {
  //   // Special case for locale debugging (/nl-lk, etc)
  //   return
  // }

  if (!to.fullPath.startsWith(`/${$i18n.locale.value}`)) {
    // If URL is not localized yet, e.g. /my-page that should be redirected to /se/my-page by i18n, we must NOT interfere
    return
  }

  const preferredLocale = $auth.getPreferredUserLocale()

  // Actually, let's extract the locale from the to.fullPath
  const toLocale = to.fullPath.split('/')[1]
  if (toLocale !== preferredLocale) {
    const redirectTo = to.fullPath.replace(
      `/${$i18n.locale.value}`,
      `/${preferredLocale}`
    )
    if (to.fullPath === redirectTo) {
      // No need to redirect, we're already on the right locale
      return
    }

    if (import.meta.server) {
      return navigateTo(redirectTo)
    } else {
      // On client, we can't handle the locale change there (i18n enters an infinite loop)
      // So, we let $auth->redirect do it for us.
    }
  }
})
