import type { AxiosPromise } from 'axios'

import type { Profile as LegacyProfile } from '../legacy-api/types/profile'

import BaseApi from './baseApi'
import { modernAccountToLegacyProfile } from './converters/profileToLegacyProfile'
import convert from './helpers/convert'

export default class Registration extends BaseApi {
  signUp(data: {
    user: {
      accept_newsletter?: boolean
      country?: string // ISO 3166-1 alpha-2
      email: string
      languages?: string[]
      password: string
    }
  }): AxiosPromise<LegacyProfile> {
    return convert(
      this.http({
        method: 'post',
        url: `/user/v1/registrations`,
        data,
      }),
      modernAccountToLegacyProfile
    )
  }
}
