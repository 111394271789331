import consola from 'consola'
import { Auth } from './auth/core'

export default defineNuxtPlugin({
  name: 'auth',
  async setup(nuxtApp) {
    const { $hummingbird, $store } = nuxtApp
    const $auth = new Auth(nuxtApp)

    nuxtApp.provide('auth', $auth)
    $auth.setupHummingbird($hummingbird)
    $auth.setupVuexStore($store)

    try {
      await nuxtApp.$auth.init()
    } catch (error: any) {
      if (import.meta.client) {
        // We shouldn't log an error when the auth_token expires.
        // However, NX5 doesn't let us know if it expired, or if it's a different error.
        const logger = consola.withTag('Auth plugin')
        logger.error(error)
        nuxtApp.$sentry.captureException(error)
      }
    }
  },
})
